<template>
  <b-row>
    <b-col cols="12">
      <form-wizard-icon
        :customer="customer"
        :isSignAgain='true'
        :clearCustomerData="clearCustomerData"
        @sign-again="signAgain"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import FormWizardIcon from "./FormWizardIcon.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import customerStoreModule from '../customerStoreModule'
import router from "@/router";

export default {
  components: {
    BRow,
    BCol,
    FormWizardIcon,
  },
  setup() {
    const CUSTOMER_STORE_MODULE_NAME = 'app-customer'

    // Register module
    if (!store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_STORE_MODULE_NAME, customerStoreModule)

    // Unregister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_STORE_MODULE_NAME)
    })

    const blankCustomer = {
      id: null,
      "adresse": {
        "kanton": "",
        "ort": "",
        "postleitzahl": "",
        "strassename": "",
        "strassenummer": ""
      },
      "anamnese": {
        "answers": [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true
        ],
        "unterschrift": ""
      },
      "emailadresse": "",
      "geburtsdatum": "",
      "gender": "Mann",
      "kundenvereinbarung": {
        "vereinbarung_1": "vereinbarung1",
        "vereinbarung_2": "vereinbarung2",
        "vereinbarung_3": "vereinbarung3",
        "visum_1": "",
        "visum_2": "",
        "visum_3": ""
      },
      "marketingvereinbarung": true,
      "mitarbeiter_id": parseInt(router.currentRoute.params.mitarbeiter_id),
      "nachname": "",
      "telefonnummer": "",
      "vorname": ""
    };
    const customer = ref(JSON.parse(JSON.stringify(blankCustomer)));
    const clearCustomerData = () => {
      customer.value = JSON.parse(JSON.stringify(blankCustomer));
    };

    store
      .dispatch("app-customer/fetchCustomer", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        customer.value = response.data;
        customer.value.anamnese.unterschrift = "";
        customer.value.kundenvereinbarung.visum_1 = "";
        customer.value.kundenvereinbarung.visum_2 = "";
        customer.value.kundenvereinbarung.visum_3 = "";
        customer.value.mitarbeiter_id = parseInt(router.currentRoute.params.mitarbeiter_id)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          customer.value = undefined;
        }
      });

    const signAgain = (customerData) => {
      store
        .dispatch("app-customer/signAgain", customerData)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          router.push({ name: "customers-success" });
        });
    };

    return {
      customer,
      clearCustomerData,
      signAgain,
    }
  },
  data() {
    return {
      // Store the value in data before we update the value so later we can restore the same value when user leaves the page
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
    };
  },
  created() {
    // Set `appConfig` when page/SFC is created
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
  },
  destroyed() {
    // Restore the state value of `appConfig` when page/SFC is destroyed
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.business-card .business-items .business-item {
  padding: 0.6rem 1rem 0rem 1rem !important;
}
hr.dotted {
  margin-top: 30px;
  border-top: 3px dotted #bbb;
}
</style>
